body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.button {
    margin: 20px;
    float: left;
    color: white;
    padding: 5px 8px;
    font-size: 1.5em;
}

div.button:hover {
    background:rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    cursor: pointer;
}
div.navbar {
    width: 95%;
    padding-left: 0;
}

div.postExcerpt {
    width: 26%;
    margin: 0 2% 0 1%;
    padding: 20px 2% 0 2%;
    float: left;
}

div.postExcerpt:hover {
    margin: 10px 2% 0 1%;
    padding: 10px 2% 0 2%;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 40px;
}

div.post {
    width: 70%;
    margin: 0 2% 0 1%;
    padding: 20px 10% 0 5%;
    float: left;
}

div.author {
    font-style: italic;
}

h2 {
    font-style: italic;
    font-size: 10px;
}
div.Main1 {
  text-align: justify;
  background-image: url(/static/media/main1.e3695ad7.png);
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 30px 0 0 2%;
  position: relative;
  min-height: 2000px;
}

div.temp-home {
  height: 1000px;
  padding: 10%;
  text-align: center;
  font-size: 3em;
}


/*.Main2 {
  text-align: justify;
  padding: 30px 0 0 2%;
  position: relative;
}*/

.Main4 {
  content: "";
  background-image: url(/static/media/main2.ad7ef24d.png);
  background-repeat: no-repeat;
  background-size: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  position: absolute;
  z-index: 0;
  transition: opacity 4s;
  min-height: 2000px;
}

.Main3 {
  z-index: 1;
  position: relative;
}

div.header {
  margin: 75px auto 50px 0;
  padding-left: 0;
  
  /*background-image: url("../images/title.png");
  background-repeat: no-repeat;
  background-size: 50%; */
}

div.header img {
  width: 50%;
  padding: 0;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0);
}

div.content {
  background-color:rgba(255, 255, 255, 0.65);
  /*opacity: 0.65; */
  width: 75%;
  margin: 75px 0 0 0;
  border-radius: 40px;
  float: left;
}

div.clearing {
  clear: both;
}

h1 {
  text-align: left;
}

img {
  max-width: 80%;
  padding: 20px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.65);
  border-color: black;
  border: 2px;
}

div.gallery {
    min-height: 1000px;
    width: 100%;
    margin: 0 2% 0 1%;
    padding: 20px 2% 20px 2%;
}

div.img_container {
    width: 25%;
    /*height: 150px; */
    margin: 0;
    padding-top: 25%;
    float: left;
    border-width: 0;
    border-style: none;
    position: relative;
}

div.img_container:hover {
    opacity: 0.5;
}

img.pic_thumbnail {
    display: block;
    position: absolute;
    object-fit: cover;
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0;
    border-radius: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

div.hoverPic {
    position: fixed;
    z-index: 1;
    padding-top: 10px;
    padding-bottom: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    overflow: auto;
    text-align: center;
}

img.full_picture {
    margin: 10px auto;
    display: block;
    max-width: 80vw;
    max-height: 80vh;
    padding: 0;
}

figcaption {
    color: white;
}

span.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: white;
    font-size: 1.2em;
    font-weight: bold;
}

span.close:hover, span.close:focus {
    cursor: pointer;
}



div.sidebar {
    background-color: white;
    opacity: 0.85;    
    width: 18%;
    height: 600px;
    margin: 200px 2% 0 2%;
    padding: 10px 1% 10px 2%;
    border-radius: 40px;
    float: left;
}

div.sidebar li {
    font-size: 1.5em;
    font-weight: normal;
    text-align: left;
    list-style-type: none;
    margin: 25px 0 0 0;
    padding: 5px;
}

div.sidebar li:hover {
    background:rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    cursor: pointer;
}
