div.postExcerpt {
    width: 26%;
    margin: 0 2% 0 1%;
    padding: 20px 2% 0 2%;
    float: left;
}

div.postExcerpt:hover {
    margin: 10px 2% 0 1%;
    padding: 10px 2% 0 2%;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 40px;
}

div.post {
    width: 70%;
    margin: 0 2% 0 1%;
    padding: 20px 10% 0 5%;
    float: left;
}

div.author {
    font-style: italic;
}

h2 {
    font-style: italic;
    font-size: 10px;
}