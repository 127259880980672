div.button {
    margin: 20px;
    float: left;
    color: white;
    padding: 5px 8px;
    font-size: 1.5em;
}

div.button:hover {
    background:rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    cursor: pointer;
}