div.gallery {
    min-height: 1000px;
    width: 100%;
    margin: 0 2% 0 1%;
    padding: 20px 2% 20px 2%;
}

div.img_container {
    width: 25%;
    /*height: 150px; */
    margin: 0;
    padding-top: 25%;
    float: left;
    border-width: 0;
    border-style: none;
    position: relative;
}

div.img_container:hover {
    opacity: 0.5;
}

img.pic_thumbnail {
    display: block;
    position: absolute;
    object-fit: cover;
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0;
    border-radius: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

div.hoverPic {
    position: fixed;
    z-index: 1;
    padding-top: 10px;
    padding-bottom: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    overflow: auto;
    text-align: center;
}

img.full_picture {
    margin: 10px auto;
    display: block;
    max-width: 80vw;
    max-height: 80vh;
    padding: 0;
}

figcaption {
    color: white;
}

span.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: white;
    font-size: 1.2em;
    font-weight: bold;
}

span.close:hover, span.close:focus {
    cursor: pointer;
}


