div.sidebar {
    background-color: white;
    opacity: 0.85;    
    width: 18%;
    height: 600px;
    margin: 200px 2% 0 2%;
    padding: 10px 1% 10px 2%;
    border-radius: 40px;
    float: left;
}

div.sidebar li {
    font-size: 1.5em;
    font-weight: normal;
    text-align: left;
    list-style-type: none;
    margin: 25px 0 0 0;
    padding: 5px;
}

div.sidebar li:hover {
    background:rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    cursor: pointer;
}