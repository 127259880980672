div.Main1 {
  text-align: justify;
  background-image: url("../images/main1.png");
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 30px 0 0 2%;
  position: relative;
  min-height: 2000px;
}

div.temp-home {
  height: 1000px;
  padding: 10%;
  text-align: center;
  font-size: 3em;
}


/*.Main2 {
  text-align: justify;
  padding: 30px 0 0 2%;
  position: relative;
}*/

.Main4 {
  content: "";
  background-image: url("../images/main2.png");
  background-repeat: no-repeat;
  background-size: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  position: absolute;
  z-index: 0;
  transition: opacity 4s;
  min-height: 2000px;
}

.Main3 {
  z-index: 1;
  position: relative;
}

div.header {
  margin: 75px auto 50px 0;
  padding-left: 0;
  
  /*background-image: url("../images/title.png");
  background-repeat: no-repeat;
  background-size: 50%; */
}

div.header img {
  width: 50%;
  padding: 0;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0);
}

div.content {
  background-color:rgba(255, 255, 255, 0.65);
  /*opacity: 0.65; */
  width: 75%;
  margin: 75px 0 0 0;
  border-radius: 40px;
  float: left;
}

div.clearing {
  clear: both;
}

h1 {
  text-align: left;
}

img {
  max-width: 80%;
  padding: 20px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.65);
  border-color: black;
  border: 2px;
}
